// red
$red-500: 		#F44336;
$red-600: 		#E53935;
$red-700: 		#D32F2F;
// pink
$pink-500: 		#E91E63;
$pink-600: 		#D81B60;
$pink-700: 		#C2185B;
// purple
$purple-500: 	#9C27B0;
$purple-600: 	#8E24AA;
$purple-700: 	#7B1FA2;
// indigo
$indigo-200: 	#9FA8DA;
$indigo-300: 	#7986CB;
$indigo-400: 	#5C6BC0;
$indigo-500: 	#3F51B5;
$indigo-600: 	#3949AB;
$indigo-700: 	#303F9F;
// blue
$blue-500: 		#2196F3;
$blue-600: 		#1E88E5;
$blue-700: 		#1976D2;
// cyan
$cyan-500: 		#00BCD4;
$cyan-600: 		#00ACC1;
$cyan-700: 		#0097A7;
// teal
$teal-500: 		#009688;
$teal-600: 		#00897B;
$teal-700: 		#00796B;
// green
$green-500: 	#4CAF50;
$green-600: 	#43A047;
$green-700: 	#388E3C;
// yellow
$yellow-500: 	#FFEB3B;
$yellow-600: 	#FDD835;
$yellow-700: 	#FBC02D;
// orange
$orange-500: 	#FF9800;
$orange-600: 	#FB8C00;
$orange-700: 	#F57C00;
// brown
$brown-500: 	#795548;
$brown-600: 	#6D4C41;
$brown-700: 	#5D4037;
// gray
$gray-100: 		#F5F5F5;
$gray-200: 		#EEEEEE;
$gray-300: 		#E0E0E0;
$gray-400: 		#BDBDBD;
$gray-500: 		#9E9E9E;
$gray-600: 		#757575;
$gray-700: 		#616161;